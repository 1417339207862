<template>
	<div class="last">
		<section
			class="main__box main__box--big main__box--bg-circles-dark bg--gray"
		>
			<div class="container">
				<div class="flex flex--center">
					<router-link class="button" :to="{ name: data }">
						Связаться с нами
					</router-link>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
export default {
	name: 'ContactsBottomBlock',

	props: {
		data: {
			type: String,
			required: true,
		},
	},
}
</script>

<style>
@media (max-width: 420px){
	.main__box {
		padding-bottom: 15px!important;
	}
}
</style>